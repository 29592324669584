
import { computed, defineComponent, reactive, toRefs, ref } from 'vue';
import { useRoute } from 'vue-router';
import { sunriseDefinitions } from './config';
import useBreakpoints from '@/composables/useBreakpoints';

import polarImg from './polar.svg';
import orbitalImg from './orbital.svg';
import uspImg from './usp.svg';
import ethernalImg from './ethernal.svg';
import binarisImg from './binaris.svg';
import tripolarImg from './tripolar.svg';

import useWeb3 from '@/services/web3/useWeb3';

import useSunrise from '../../composables/PolarisFinance/useSunrise';
import useTreasury from '../../composables/PolarisFinance/useTreasury';
import useTokens from '../../composables/PolarisFinance/useTokens';

import SpolarModal from './SpolarModal.vue';
import { parseFixed } from '@ethersproject/bignumber';

import useTransactions from '@/composables/useTransactions';
import { TransactionResponse } from '@ethersproject/providers';
import isDate from 'date-fns/esm/isDate/index.js';
import useEthers from '../../composables/useEthers';

interface PoolPageData {
  id: string;
}

function getLength(number) {
  return number.toString().length;
}

function formatDateNumber(number) {
  return getLength(number) == 2 ? number : '0' + number;
}

function formatDate(hours, minutes, seconds) {
  return (
    formatDateNumber(hours) +
    ':' +
    formatDateNumber(minutes) +
    ':' +
    formatDateNumber(seconds)
  );
}

// async function setDate(event, instance, property, epochTimer = true) {
//   var date = await event();

//   var interval = setInterval(async function () {
//     var now = new Date().getTime();
//     var distance = date.getTime() - now;

//     if (distance >= 0) {
//       var hours = Math.floor(
//         (distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
//       );
//       var minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
//       var seconds = Math.floor((distance % (1000 * 60)) / 1000);

//       instance[property] = formatDate(hours, minutes, seconds);
//     } else {
//       instance[property] = '00:00:00';
//       if (epochTimer) date = await event();
//       else clearInterval(interval);
//     }
//   }, 1000);
// }

export default defineComponent({
  components: { SpolarModal },
  setup() {
    const { addTransaction } = useTransactions();
    const { txListener } = useEthers();

    const txHandler = (tx: TransactionResponse): void => {
      addTransaction({
        id: tx.hash,
        type: 'tx',
        action: 'approve',
        summary: 'deposit for sunrise',
      });
    };
    const route = useRoute();
    const route_id = route.params.id;
    var sunriseName = route_id.toString();

    const { isMobile, isDesktop } = useBreakpoints();

    const { account, getProvider } = useWeb3();

    const logo = {
      polar: polarImg,
      orbital: orbitalImg,
      usp: uspImg,
      ethernal: ethernalImg,
      binaris: binarisImg,
      tripolar: tripolarImg,
    };
    const data = reactive<PoolPageData>({
      id: route.params.id as string,
    });

    const sunrise = computed(() => {
      for (let sunrise of Object.values(sunriseDefinitions)) {
        if (sunrise.name == data.id) return sunrise;
      }
      return undefined;
    });

    const isSpolarModalVisible = ref(false);
    const isSpolarWithdrawModalVisible = ref(false);
    const depositToken = ref(false);

    const toggleSpolarModal = (depositProp: boolean, value?: boolean) => {
      isSpolarModalVisible.value = value ?? !isSpolarModalVisible.value;
      depositToken.value = depositProp;
    };

    const toggleSpolarWithdrawModal = (
      depositProp: boolean,
      value?: boolean
    ) => {
      isSpolarWithdrawModalVisible.value =
        value ?? !isSpolarWithdrawModalVisible.value;
      depositToken.value = depositProp;
    };
    return {
      // data
      ...toRefs(data),
      isMobile,
      isDesktop,
      logo,

      // computed
      sunrise,

      isSpolarModalVisible,
      isSpolarWithdrawModalVisible,
      toggleSpolarWithdrawModal,
      toggleSpolarModal,
      depositToken,
      account,
      getProvider,
      sunriseName,
      txHandler,
      txListener,
    };
  },
  data() {
    return {
      approved: false,
      depositAmount: '0',
      withdrawAmount: '0',
      epoch: '-',
      earned: '-',
      canWithdraw: false,
      canClaim: false,
      spolarsStaked: '-',
      lastEpochTwap: '-',
      twap: '-',
      printTwap: '-',
      depositedInDollars: '-',
      earnedAmountInDollars: '-',
      nextEpoch: '-',
      nextEpochDate: new Date(),
      APR: '-',
      withdrawTime: '-',
      withdrawTimeDate: new Date(),
      claimTime: '-',
      claimTimeDate: new Date(),
      depositedBalance: '-',
      balance: '-',
    };
  },
  methods: {
    async claim() {
      const { claim } = useSunrise(this.sunriseName);
      const tx = await claim(this.getProvider());
      this.txHandler(tx);
      this.txListener(tx, {
        onTxConfirmed: () => {
          this.render();
        },
        onTxFailed: () => {},
      });
    },
    async approve() {
      const { approve } = useSunrise(this.sunriseName);
      const tx = await approve(this.getProvider());
      this.txHandler(tx);
      this.txListener(tx, {
        onTxConfirmed: () => {
          this.render();
        },
        onTxFailed: () => {},
      });
    },
    async withdrawAll() {
      const amount = this.depositedBalance;
      const formatedAmount = parseFixed(amount, 18);
      const { withdraw } = useSunrise(this.sunriseName);
      const tx = await withdraw(formatedAmount, this.getProvider());
      this.txHandler(tx);
      this.txListener(tx, {
        onTxConfirmed: () => {
          this.render();
        },
        onTxFailed: () => {},
      });
    },
    async setDate(time: Date) {
      var now = new Date().getTime();
      var distance = time.getTime() - now;

      if (distance >= 0) {
        var hours = Math.floor(
          (distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
        );
        var minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
        var seconds = Math.floor((distance % (1000 * 60)) / 1000);

        return formatDate(hours, minutes, seconds);
      } else {
        return '00:00:00';
      }
    },
    async render() {
      const {
        isApproved,
        getEpoch,
        getRewardsEarned,
        canWithdraw,
        canClaimReward,
        getSpolarStaked,
        getBalance,
        getSunriseAPR,
        getUnstakePeriod,
        getClaimPeriod,
      } = useSunrise(this.sunriseName);

      const {
        getLastEpochTWAP,
        getPrintTWAP,
        getCurrentTWAP,
        getNextEpochTime,
      } = useTreasury(this.sunriseName);

      const { getSpolarBalance } = useTokens();

      const { getSpolarPrice, getTokenPriceInUSD } = useTokens();

      // this.nextEpochDate = await getNextEpochTime();
      // this.epoch = await getEpoch();
      // this.spolarsStaked = await getSpolarStaked();
      // this.lastEpochTwap = await getLastEpochTWAP();
      // this.printTwap = await getPrintTWAP();
      // this.twap = await getCurrentTWAP();
      // this.APR = await getSunriseAPR();
      if (this.account === '') {
        [
          this.nextEpochDate,
          this.epoch,
          this.spolarsStaked,
          this.lastEpochTwap,
          this.printTwap,
          this.twap,
          this.APR,
        ] = await Promise.all([
          getNextEpochTime(),
          getEpoch(),
          getSpolarStaked(),
          getLastEpochTWAP(),
          getPrintTWAP(),
          getCurrentTWAP(),
          getSunriseAPR(),
        ]);
      } else {
        let spolarPrice: number;
        let tokenUsdPrice: number;
        [
          this.nextEpochDate,
          this.epoch,
          this.spolarsStaked,
          this.lastEpochTwap,
          this.printTwap,
          this.twap,
          this.APR,
          this.approved,
          this.earned,
          this.canWithdraw,
          this.canClaim,
          this.depositedBalance,
          spolarPrice,
          tokenUsdPrice,
          this.claimTimeDate,
          this.withdrawTimeDate,
        ] = await Promise.all([
          getNextEpochTime(),
          getEpoch(),
          getSpolarStaked(),
          getLastEpochTWAP(),
          getPrintTWAP(),
          getCurrentTWAP(),
          getSunriseAPR(),
          isApproved(this.account),
          getRewardsEarned(this.account),
          canWithdraw(this.account),
          canClaimReward(this.account),
          getBalance(this.account),
          getSpolarPrice(),
          getTokenPriceInUSD(this.sunriseName),
          getClaimPeriod(this.account),
          getUnstakePeriod(this.account),
        ]);
        this.depositedInDollars = (
          parseFloat(this.depositedBalance) * spolarPrice
        )
          .toFixed(2)
          .toString();

        this.earnedAmountInDollars = (parseFloat(this.earned) * tokenUsdPrice)
          .toFixed(2)
          .toString();

        this.balance = await getSpolarBalance(this.account);
      }

      // await setDate(getClaimPeriod, this, 'claimTime');
      // await setDate(getNextEpochTime, this, 'nextEpoch');
    },
  },
  async created() {
    setInterval(
      async () => (this.claimTime = await this.setDate(this.claimTimeDate)),
      1000
    );
    setInterval(
      async () =>
        (this.withdrawTime = await this.setDate(this.withdrawTimeDate)),
      1000
    );
    setInterval(
      async () => (this.nextEpoch = await this.setDate(this.nextEpochDate)),
      1000
    );
    await this.render();
  },
  watch: {
    async account(newAccount, oldAccount) {
      await this.render();
    },
    async nextEpoch(newEpoch, oldEpoch) {
      if (newEpoch === '00:00:00') {
        await this.render();
      }
    },
  },
});

export const sunriseDefinitions = {
  PolarSunrise: {
    name: 'polar',
    contract: 'Masonry',
    earnTokenName: 'POLAR',
    sort: 3,
    boosted: false,
    coming: false,
    retired: false,
    bond: 'PBOND',
    tokenAddress: '0xf0f3b9Eee32b1F490A4b8720cf6F005d4aE9eA86',
    lpAddress: '0x3fa4d0145a0b6Ad0584B1ad5f61cB490A04d8242',
    treasury: 'Treasury',
    lpToken: 'NEAR',
    getTokenPreviousEpochTWAP: 'previousEpochpolarPrice',
    getTokenPriceInLastTWAP: 'getpolarUpdatedPrice',
    getBondsPurchasable: 'getBurnablepolarLeft',
    getPrice: 'getpolarPrice',
    oracle: 'SeigniorageOracle',
  },
  LunarSunrise: {
    name: 'lunar',
    contract: 'lunarSunrise',
    earnTokenName: 'LUNAR',
    sort: 4,
    boosted: false,
    coming: false,
    retired: true,
    bond: 'LBOND',
    tokenAddress: '0x25e801Eb75859Ba4052C4ac4233ceC0264eaDF8c',
    lpAddress: '0x3e50da46cB79d1f9F08445984f207278796CE2d2',
    treasury: 'lunarTreasury',
    lpToken: 'LUNA',
    retireMsg:
      'Please withdraw your SPOLAR and migrate them to other active Sunrises.',
    getTokenPreviousEpochTWAP: 'previousEpochlunarPrice',
    getTokenPriceInLastTWAP: 'getlunarUpdatedPrice',
    getBondsPurchasable: 'getBurnablelunarLeft',
    getPrice: 'getlunarPrice',
    oracle: 'LunarOracle',
  },
  OldTripolarSunrise: {
    name: 'tripolarOld',
    contract: 'tripolarSunriseOld',
    earnTokenName: 'OLDTRIPOLAR',
    sort: 2,
    boosted: false,
    coming: false,
    retired: true,
    bond: 'TRIBOND',
    tokenAddress: '0x60527a2751A827ec0Adf861EfcAcbf111587d748',
    lpAddress: '0x85f155FDCf2a951fd95734eCEB99F875b84a2E27',
    treasury: 'tripolarTreasuryOld',
    lpToken: 'xTRI',
    retireMsg:
      'Please withdraw your SPOLAR and migrate them to the NEW TRIPOLAR Sunrise.',
    getTokenPreviousEpochTWAP: 'previousEpochTripolarPrice',
    getTokenPriceInLastTWAP: 'getTripolarUpdatedPrice',
    getBondsPurchasable: 'getBurnableTripolarLeft',
    getPrice: 'getTripolarPrice',
    oracle: 'TripolarOracle',
  },
  EthernalSunrise: {
    name: 'ethernal',
    contract: 'ethernalSunrise',
    earnTokenName: 'ETHERNAL',
    sort: 2,
    boosted: false,
    coming: false,
    retired: false,
    bond: 'EBOND',
    tokenAddress: '0x17cbd9C274e90C537790C51b4015a65cD015497e',
    lpAddress: '0x81D77f8e86f65b9C0F393afe0FC743D888c2d4d7',
    treasury: 'ethernalTreasury',
    lpToken: 'WETH',
    getTokenPreviousEpochTWAP: 'previousEpochEthernalPrice',
    getTokenPriceInLastTWAP: 'getEthernalUpdatedPrice',
    getBondsPurchasable: 'getBurnableEthernalLeft',
    getPrice: 'getEthernalPrice',
    oracle: 'EthernalOracle',
  },
  OldEthernalSunrise: {
    name: 'ethernalOld',
    contract: 'ethernalSunriseOld',
    earnTokenName: 'ETHERNAL',
    sort: 2,
    boosted: false,
    coming: false,
    retired: true,
    bond: 'EBOND',
    tokenAddress: '0x17cbd9C274e90C537790C51b4015a65cD015497e',
    lpAddress: '0x81D77f8e86f65b9C0F393afe0FC743D888c2d4d7',
    treasury: 'ethernalTreasuryOld',
    lpToken: 'WETH',
    getTokenPreviousEpochTWAP: 'previousEpochEthernalPrice',
    getTokenPriceInLastTWAP: 'getEthernalUpdatedPrice',
    getBondsPurchasable: 'getBurnableEthernalLeft',
    getPrice: 'getEthernalPrice',
    oracle: 'EthernalOracle',
    retireMsg:
      'Please withdraw your SPOLAR and migrate them to the NEW ETHERNAL Sunrise.',
  },
  BtcSunrise: {
    name: 'orbital',
    contract: 'orbitalSunrise',
    earnTokenName: 'ORBITAL',
    sort: 1,
    boosted: false,
    coming: false,
    retired: false,
    notActive: false,
    bond: 'OBOND',
    tokenAddress: '0x3AC55eA8D2082fAbda674270cD2367dA96092889',
    lpAddress: '0x7243cB5DBae5921c78A022110645a23a38ffBA5D',
    treasury: 'orbitalTreasury',
    lpToken: 'WBTC',
    getTokenPreviousEpochTWAP: 'previousEpochOrbitalPrice',
    getTokenPriceInLastTWAP: 'getOrbitalUpdatedPrice',
    getBondsPurchasable: 'getBurnableOrbitalLeft',
    getPrice: 'getOrbitalPrice',
    oracle: 'OrbitalOracle',
  },
  OldBtcSunrise: {
    name: 'orbitalOld',
    contract: 'orbitalSunriseOld',
    earnTokenName: 'ORBITAL',
    sort: 1,
    boosted: false,
    coming: false,
    retired: true,
    notActive: false,
    bond: 'OBOND',
    tokenAddress: '0x3AC55eA8D2082fAbda674270cD2367dA96092889',
    lpAddress: '0x7243cB5DBae5921c78A022110645a23a38ffBA5D',
    treasury: 'orbitalTreasuryOld',
    lpToken: 'WBTC',
    getTokenPreviousEpochTWAP: 'previousEpochOrbitalPrice',
    getTokenPriceInLastTWAP: 'getOrbitalUpdatedPrice',
    getBondsPurchasable: 'getBurnableOrbitalLeft',
    getPrice: 'getOrbitalPrice',
    oracle: 'OrbitalOracle',
    retireMsg:
      'Please withdraw your SPOLAR and migrate them to the NEW ORBITAL Sunrise.',
  },
  UspSunrise: {
    name: 'usp',
    contract: 'uspSunrise',
    earnTokenName: 'USP',
    sort: 0,
    boosted: false,
    coming: false,
    retired: false,
    notActive: false,
    bond: 'USPBOND',
    tokenAddress: '0xa69d9Ba086D41425f35988613c156Db9a88a1A96',
    lpAddress: '0xa984B8062316AFE25c86576b0478E76E65FdF564',
    treasury: 'uspTreasury',
    lpToken: 'USDC',
    getTokenPreviousEpochTWAP: 'previousEpochUspPrice',
    getTokenPriceInLastTWAP: 'getUspUpdatedPrice',
    getBondsPurchasable: 'getBurnableUspLeft',
    getPrice: 'getUspPrice',
    oracle: 'UspOracle',
  },
  OldUspSunrise: {
    name: 'uspOld',
    contract: 'uspSunriseOld',
    earnTokenName: 'USP',
    sort: 0,
    boosted: false,
    coming: false,
    retired: true,
    notActive: false,
    bond: 'USPBOND',
    tokenAddress: '0xa69d9Ba086D41425f35988613c156Db9a88a1A96',
    lpAddress: '0xa984B8062316AFE25c86576b0478E76E65FdF564',
    treasury: 'uspTreasuryOld',
    lpToken: 'USDC',
    getTokenPreviousEpochTWAP: 'previousEpochUspPrice',
    getTokenPriceInLastTWAP: 'getUspUpdatedPrice',
    getBondsPurchasable: 'getBurnableUspLeft',
    getPrice: 'getUspPrice',
    oracle: 'UspOracle',
    retireMsg:
      'Please withdraw your SPOLAR and migrate them to the NEW USP Sunrise.',
  },
  BinarisSunrise: {
    name: 'binaris',
    contract: 'binarisSunrise',
    earnTokenName: 'BINARIS',
    sort: 4,
    boosted: false,
    coming: false,
    retired: false,
    notActive: false,
    bond: 'BBOND',
    tokenAddress: '0xafE0d6ca6AAbB43CDA024895D203120831Ba0370',
    lpAddress: '0x29A3e2Bb73891f20C6d4A34ecE0c4a6F8020ec32',
    treasury: 'binarisTreasury',
    lpToken: 'BNB',
    getTokenPreviousEpochTWAP: 'previousEpochBinarisPrice',
    getTokenPriceInLastTWAP: 'getBinarisUpdatedPrice',
    getBondsPurchasable: 'getBurnableBinarisLeft',
    getPrice: 'getBinarisPrice',
    oracle: 'BinarisOracle',
  },
  OldBinarisSunrise: {
    name: 'binarisOld',
    contract: 'binarisSunriseOld',
    earnTokenName: 'BINARIS',
    sort: 4,
    boosted: false,
    coming: false,
    retired: true,
    notActive: false,
    bond: 'BBOND',
    tokenAddress: '0xafE0d6ca6AAbB43CDA024895D203120831Ba0370',
    lpAddress: '0x29A3e2Bb73891f20C6d4A34ecE0c4a6F8020ec32',
    treasury: 'binarisTreasuryOld',
    lpToken: 'BNB',
    getTokenPreviousEpochTWAP: 'previousEpochBinarisPrice',
    getTokenPriceInLastTWAP: 'getBinarisUpdatedPrice',
    getBondsPurchasable: 'getBurnableBinarisLeft',
    getPrice: 'getBinarisPrice',
    oracle: 'BinarisOracle',
    retireMsg:
      'Please withdraw your SPOLAR and migrate them to the NEW BINARIS Sunrise.',
  },
  AurisSunrise: {
    name: 'tripolar',
    contract: 'tripolarSunrise',
    earnTokenName: 'AURIS',
    sort: 6,
    boosted: false,
    coming: true,
    retired: true,
    bond: 'ABOND',
    tokenAddress: '',
    lpAddress: '',
    treasury: 'tripolarTreasury',
    lpToken: 'AURORA',
    getTokenPreviousEpochTWAP: 'previousEpochTripolarPrice',
    getTokenPriceInLastTWAP: 'getTripolarUpdatedPrice',
    getBondsPurchasable: 'getBurnableTripolarLeft',
    getPrice: 'getTripolarPrice',
    oracle: 'TripolarOracle',
  },
};
